
.admmin-container{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 0 6px 24px 6px;
}

.form{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.form input{
  border: 0;
  height: 35px;
  margin-bottom: 14px;
  border-radius: 4px;
  padding: 0 8px;
  outline: none;
}
.form label{
  color: #FFF;
  font-weight: medium;
  font-size: 16.5441px;
  line-height: 25px;
  margin-bottom: 14px;
}
.form button{
  border: 0;
  height: 35px;
  border-radius: 4px;
  background-color: #3366ff;
  font-size: 18px;
  color: #FFF;
  cursor: pointer;
}

.form button:hover{
  background-color: #5c83f7;
}

.container-colors{
  display: flex;
  margin: 14px 0;
  gap: 22px;
}

.right{
  margin-right: 14px;
}

.btn-register{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 25px;
}

.title{
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #FFFFFF;
  margin: 28px 0 14px 0;
}

.list{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6.76px 11.83px 6.76px 8px;
  border-radius: 3.38px;
  width: 90%;
  max-width: 600px;
  margin-bottom: 28px;

}

.list p{
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

}

.btn-delete{
  padding: 4px 8px;
  background: #000000;
  border: 0.9295px dashed #FFFFFF;
  color: #FFFFFF;
  border-radius:2px;
}

.preview{
  margin: 0 0 28px 0;
  padding: 10px 4px;
  border:1.5px solid rgba(204, 204, 204, 0.473);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin-bottom: 28px; */
}


.animate-pop{
  animation-duration: 0.5s;
  animation-name: animate-pop;
}

@keyframes animate-pop{
  0%{
    opacity: 0;
    transform:  scale(0.7 , 0.7);
  }
  100%{
    opacity: 1;
    transform:  scale(1 , 1);
  }
}