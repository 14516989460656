
.admin-header{
  width: 100%;
  max-width: 720px;
  margin-top: 14px;
  padding: 0 14px;

}

.nav-header{
  width: 100%;
  background-color: #FFF;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.nav-header button{
  background-color: transparent;
  border: 0;
  margin-right: 24px;
  margin-left: 8px;

}


.nav-header a{
  margin-right: 14px;
  color: #21242d;
}

.nav-header a:hover{
color: #EEA819;

}