.home-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 14px 0;
  /* min-height: 100vh; */
  justify-content: center;
  align-items: center;
}
.home-container h1{
  margin-bottom: 14px;
  color: #FFF;
  font-size: 44px;
  padding-top: 94px;
}

.home-container span{
  margin-bottom: 18px;
  color: #FFF;
}

.links{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 600px;
  width: 90%;
}
.link-area{
  background: #DDDDDD;
  border-radius: 4px;
  margin-bottom: 18px;
  width: 100%;
  padding: 8px 0px;
  user-select: none;
  transition: transform 0.4s;
}
.link-area:hover{
  transform: scale(1.04);
}
.link-text{
  font-size: 18px;
  line-height:150%;
  color:#000;
}

footer{
  display: flex;
  justify-content: center;
  gap: 14px;
  margin: 18px 0;
}

@media screen and (max-width:550px){
  .home-container h1{
    font-size: 20px;
  }
}