body {
  font-family: sans-serif;
  background: linear-gradient(180deg, #232630 0%, #050507 100%);
  min-height: 100vh;

}

*{
  margin: 0;
  padding:0 ;
  box-sizing: border-box;
}

button{
  cursor: pointer;
}

a{
  text-decoration: none;
}